import { Controller, useForm } from "react-hook-form";
import CustomAutoComplete from "../../../fields/autoComplete/CustomAutoComplete";
import CardTitle from "../../dashboard/CardTitle";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AutoCompleteGroup from "../../../fields/autoComplete/AutoCompleteGroup";
import { editProject } from "../../../redux/slices/projectSlice";
import Storage from "../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../../buttons/Button";
import TextInput from "../../../fields/text/TextInput";
import useWindowSize from "../../../utils/useWindowSize";

const ProjectInfo = ({ data, viewType }) => {
  // const [openOffice, setOpenOffice] = useState(false);
  // const [openSource, setOpenSource] = useState(false);

  // const leadLoading = useSelector((state) => state.lead.loading);
  // const leadData = useSelector((state) => state.lead.leadData);

  // const officeList = useSelector((state) => state.lead.leadOffice);
  // const sourceList = useSelector((state) => state.lead.leadSource);

  // const [officeSelected, setOfficeSelected] = useState(data?.office ?? null);
  // const [sourceSelected, setSourceSelected] = useState(data?.source ?? null);
  const [setter, setSetter] = useState(data?.setter ?? null);
  const [salesRep, setSalesRep] = useState(data?.salesRep ?? null);
  const [manager, setManager] = useState(null);

  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { width } = useWindowSize();

  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(
      editProject({
        url: `crm/org/${orgId}/proj/${id}`,
        data: data,
        params: {},
      })
    );
  };

  const handleGroup = (value, type) => {
    if (type === "setter") {
      setSetter(value);
      setValue("setter", value, { shouldDirty: true });
    } else if (type === "sale") {
      setSalesRep(value);
      setValue("salesRep", value, { shouldDirty: true });
    } else if (type === "manager") {
      setManager(value);
      setValue("manager", value, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (data?.installerName) {
      setValue("installerName", data?.installerName);
    }
  }, [data]);

  return (
    <div className="card_container">
      <CardTitle title="Stakeholders" type="project_view"></CardTitle>
      <div className="card_content">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="d_flex justify_content_between">
            <AutoCompleteAsync
              sx={{ width: "150px", marginBottom: "10px" }}
              name="office"
              label="Office"
              open={openOffice}
              onOpen={() => {
                setOpenOffice(true);
              }}
              onClose={() => {
                setOpenOffice(false);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value.id}
              loading={leadLoading}
              options={officeList}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setOfficeSelected(null);
                } else {
                  setOfficeSelected(newValue);
                }
              }}
              register={register}
              renderOption={(props, option) => (
                <Box style={{ fontSize: 13 }} {...props}>
                  {option.name}
                </Box>
              )}
              value={officeSelected}
              getOptionLabel={(option) => {
                if (option.name) {
                  return option?.name;
                } else {
                  return "Please Select";
                }
              }}
              variant="standard"
            />
            <AutoCompleteAsync
              sx={{ width: "150px", marginBottom: "10px" }}
              name="source"
              label="Source"
              open={openSource}
              onOpen={() => {
                setOpenSource(true);
              }}
              onClose={() => {
                setOpenSource(false);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value.id}
              loading={leadLoading}
              options={sourceList}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setSourceSelected(null);
                } else {
                  setSourceSelected(newValue);
                }
              }}
              register={register}
              renderOption={(props, option) => (
                <Box style={{ fontSize: 13 }} {...props}>
                  {option.name}
                </Box>
              )}
              value={sourceSelected}
              getOptionLabel={(option) => {
                if (option.name) {
                  return option?.name;
                } else {
                  return "Please Select";
                }
              }}
              variant="standard"
            />
          </div> */}

          {/* <CustomAutoComplete
            name="office"
            options={[
              { label: "SkyNet HQ", value: "32322332" },
              { label: "ESR HQ", value: "32322333" },
              { label: "ABC HQ", value: "32322334" },
            ]}
            label="Office"
            variant="standard"
            sx={{ width: 300 }}
            register={register}
          /> */}
          {/* <CustomAutoComplete
            name="sales_rep"
            options={[
              { label: "Shoukath", value: "32322332" },
              { label: "Joel", value: "32322333" },
              { label: "Noble", value: "32322334" },
            ]}
            label="Sales Rep"
            variant="standard"
            sx={{ width: 300 }}
            register={register}
          />
          <CustomAutoComplete
            name="setter"
            options={[
              { label: "Joseph", value: "32322332" },
              { label: "Rahul", value: "32322332" },
              { label: "James", value: "32322332" },
              { label: "Arun", value: "32322332" },
              { label: "Shafeek", value: "32322332" },
              { label: "Noel", value: "32322332" },
              { label: "Suhail", value: "32322332" },
            ]}
            label="Setter"
            variant="standard"
            sx={{ width: 300 }}
            register={register}
          /> */}

          {/* <CustomAutoComplete
            name="source"
            options={[
              { label: "CEA Lead", value: "32322332" },
              { label: "Company Referral", value: "32322332" },
              { label: "In-House Setter Lead", value: "32322332" },
              { label: "Self Gen Lead", value: "32322332" },
              { label: "TCC", value: "32322332" },
            ]}
            label="Source"
            variant="standard"
            sx={{ width: 300 }}
            register={register}
          /> */}

          <AutoCompleteGroup
            name="salesRep"
            type="sale"
            label="Sales Rep"
            register={register}
            onChange={handleGroup}
            variant="standard"
            value={data?.salesRep}
            view="leadview"
            groupWidth={width < 1367 ? "130px" : "150px"}
            userWidth={width < 1367 ? "130px" : "150px"}
            horizontal={true}
          />
          <AutoCompleteGroup
            name="setter"
            type="setter"
            label="Setter"
            Sales
            Rep
            register={register}
            onChange={handleGroup}
            variant="standard"
            value={data?.setter}
            view="leadview"
            groupWidth={width < 1367 ? "130px" : "150px"}
            userWidth={width < 1367 ? "130px" : "150px"}
            horizontal={true}
          />

          {viewType === "project" && (
            <AutoCompleteGroup
              name="manager"
              type="manager"
              label="Project Manager"
              Sales
              Rep
              register={register}
              onChange={handleGroup}
              variant="standard"
              value={data?.manager}
              view="leadview"
              groupWidth={width < 1367 ? "130px" : "150px"}
              userWidth={width < 1367 ? "130px" : "150px"}
              horizontal={true}
            />
          )}
          {viewType === "project" && (
            <div className="">
              <label className="text_medium_bold">Installer</label>
              <Controller
                name={`installerName`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="installerName"
                    label="Name"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    fieldStyle="standard"
                  />
                )}
              />
            </div>
          )}
          <div>
            <Button type="submit" className="primary_button marginT10">
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectInfo;
