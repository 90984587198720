import React, { useRef, useState } from "react";
import "./style.scss";
import { useDrag, useDrop } from "react-dnd";
import { Edit } from "@mui/icons-material";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { setCreateSnackbar } from "../../../redux/slices/formSlice";
import { useDispatch } from "react-redux";
const options = [
  { name: "Edit", value: "Edit" },
  { name: "Delete", value: "Delete" },
  { name: "View Stages", value: "View" },
  // {name:"Check List",value:"checkList"}
];

const StatusCard2 = ({
  name,
  index,
  handleEdit,
  handleDelete,
  handleViewStageClick,
  key,
  moveCardHandler,
  stage,
  handleCheckListClick,
}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [, drop] = useDrop({
    accept: "Our first type",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCardHandler(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "Our first type",
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const handleSelect = (value) => {
    if (value === "Edit") {
      handleEdit(stage);
    } else if (value === "Delete") {
      if (Number(stage.count) == 0) {
        setOpenDeleteConfirmation(true);
      } else {
        dispatch(
          setCreateSnackbar({
            message: "Cant Delete Status.Project inside",
            createSuccess: false,
            statusCode: 404,
          })
        );
      }
    } else if (value === "View") {
      handleViewStageClick(stage);
    } else if (value === "checkList") {
      handleCheckListClick(stage);
    }
  };

  return (
    <>
      <div
        className="stage_card"
        ref={ref}
        style={{ opacity: isDragging ? 0.25 : 1 }}
        key={key}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>
          <div
            style={{
              color: "black",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "bolder",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleViewStageClick(stage);
            }}
          >
            {name}
          </div>
          <div>
            <ThreeDotDropdown
              options={options}
              onSelect={(value) => handleSelect(value)}
            />
          </div>
        </div>
      </div>
      <CustomDialog
        open={openDeleteConfirmation}
        title="Delete"
        message="Are you sure you want to delete this Status?"
        okText="Delete"
        cancelText="Cancel"
        onOk={() => {
          setOpenDeleteConfirmation(false);
          handleDelete(stage);
        }}
        onCancel={() => setOpenDeleteConfirmation(false)}
      />
    </>
  );
};

export default StatusCard2;
