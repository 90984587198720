import { IconButton } from "@mui/material";
import CloseIcon from "../../../../assets/images/close";
import { useForm } from "react-hook-form";
import DragDropUploader from "../../../../components/fileupload/DragDropUploader";
import {
  deleteFile,
  fileUpload,
  singleFileUpload,
} from "../../../../redux/slices/fileUploadSlice";
import {
  editProject,
  fileUploadProject,
} from "../../../../redux/slices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Storage from "../../../../utils/Storage";
import Button from "../../../buttons/Button";
import {
  setCreateSnackbar,
  setCreateSuccess,
} from "../../../../redux/slices/formSlice";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { setMinimize } from "../../../../redux/slices/modalSlice";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

const FileUploadForm = ({ onClose, type, folderId, title, typeId }) => {
  const currentFile = useSelector((state) => state.upload.currentFile);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const uploadLoading = useSelector((state) => state.upload.loading);
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const dispatch = useDispatch();
  const [deletedFiles, setDeletedFiles] = useState([]);
  const uploadDetails = useSelector((state) => state.modal.uploadDetails);
  const modalMinmize = useSelector((state) => state.modal.modalMinmize);
  const progress = useSelector((state) => state.upload.progress);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const handleProgress = (progress) => {};

  const onSubmit = (data) => {
    if (currentFile && currentFile?.length > 0) {
      data = {
        added: currentFile.map((file, index) => {
          return {
            name: file?.name,
            url: file?.url,
            updated: true,
            folderid: folderId,
          };
        }),
      };
      if (uploadLoading) {
        dispatch(
          setCreateSnackbar({
            message:
              "You can save the project only after the file upload is completed",
            createSuccess: true,
            statusCode: 600,
          })
        );
      } else {
        dispatch(
          fileUploadProject({
            url: `crm/org/${orgId}/${type}/${typeId}/files`,
            data: data,
            params: {},
          })
        );
      }
    }
  };

  const handleFile = (files) => {
    if (files.length > 0) {
      if (type) {
        dispatch(
          fileUpload({
            url: `crm/org/${orgId}/${type}/${typeId}/file/${title}/${folderId}`,
            data: files,
            params: {},
          })
        );
      } else {
        const formData = new FormData();
        formData.append("logo", files[0]);

        dispatch(
          singleFileUpload({
            url: `/crm/org/${id}/files/logo`,
            data: formData,
            params: {},
            onUploadProgress: handleProgress,
          })
        );
      }
    }
  };

  const fileDelete = (name) => {
    if (currentFile && currentFile?.length > 0) {
      const deletedUrl = currentFile
        .filter((file, index) => file.name === name)
        .map((item) => {
          return { url: item.url };
        });
      dispatch(
        deleteFile({
          url: `crm/org/${orgId}/files/delete`,
          data: deletedUrl,
          params: {},
        })
      );
    }
  };

  const deleteAll = () => {
    if (currentFile && currentFile?.length > 0) {
      const deletedUrl = currentFile.map((item) => {
        return { url: item.url };
      });
      dispatch(
        deleteFile({
          url: `crm/org/${orgId}/files/delete`,
          data: deletedUrl,
          params: {},
        })
      );
    }
  };

  const handleClose = () => {
    deleteAll();
    onClose();
  };

  const handleMinimize = () => {
    uploadDetails.onMinimize();

    modalMinmize ? dispatch(setMinimize(false)) : dispatch(setMinimize(true));
  };

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">{title}</p>
        <div className="modal_header_button_wrapper">
          {type && (
            <IconButton onClick={handleMinimize}>
              {modalMinmize ? <OpenInFullIcon /> : <MinimizeIcon />}
            </IconButton>
          )}

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal_body">
          <div style={{ marginTop: "32px", height: "300px" }}>
            <DragDropUploader
              onChange={handleFile}
              onDelete={fileDelete}
              progress={progress}
            />
          </div>
        </div>
        {type && (
          <div className="modal_footer">
            <Button type="submit" className="primary_button">
              Update
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export default FileUploadForm;
