import { createSlice } from "@reduxjs/toolkit";

const fileUploadSlice = createSlice({
  name: "upload",
  initialState: {
    uploadedFiles: null,
    currentFile: [],
    folderList: null,
    fileList: null,
    loading: false,
    fileLoading: false,
    successData: null,
    singleUpSuccess: false,
    creatingFolder: false,
    progress: [],
    fileIndex: 0,
    currentGroupLength: 0,
    currentGroupIndex: 0,
  },
  reducers: {
    fileUpload(state) {
      state.loading = true;
      return state;
    },

    singleFileUpload(state) {
      state.loading = true;
      return state;
    },
    createNewFolder(state) {
      state.loading = true;
      state.creatingFolder = true;
      return state;
    },
    getFolderList(state) {
      state.loading = true;
      return state;
    },
    getFileList(state) {
      state.fileLoading = true;
      return state;
    },
    folderBulkDownload(state) {
      state.loading = true;
      return state;
    },
    deleteFiles(state) {
      state.loading = true;
      return state;
    },

    fileUploadSuccess: (state, action) => {
      state.currentFile = [...state.currentFile, ...action.payload.files];
      state.loading = false;
    },

    singleFileUploadSuccess: (state, action) => {
      state.singleUpSuccess = action.payload;
      state.loading = false;
    },
    folderCreateSuccess: (state, action) => {
      state.creatingFolder = false;
    },

    setFolderList: (state, action) => {
      state.folderList = action.payload;
      state.loading = false;
    },

    setFileList: (state, action) => {
      state.fileList = action.payload;
      state.fileLoading = false;
    },

    clearCurrentFile: (state, action) => {
      state.currentFile = [];
    },

    deleteFile: (state, action) => {
      state.uploadedFiles = action.payload;
      state.loading = false;
    },
    setFileUploadLoadingFalse: (state, action) => {
      state.loading = false;
    },
    setSuccess: (state, action) => {
      state.successData = action.payload;
      state.loading = false;
    },
    uploadFileProgress: (state, action) => {
      const { progress, group } = action.payload;
      group.map((item) => (state.progress[item.fileIndex] = progress));
    },
  },
});

export const {
  fileUpload,
  singleFileUpload,
  createNewFolder,
  getFolderList,
  setFolderList,
  getFileList,
  setFileList,
  fileUploadSuccess,
  singleFileUploadSuccess,
  deleteFile,
  setFileUploadLoadingFalse,
  clearCurrentFile,
  folderBulkDownload,
  deleteFiles,
  setSuccess,
  uploadFileProgress,
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
