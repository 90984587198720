import { call, fork, put, takeLatest } from "redux-saga/effects";
import { createItem, editItem, getItem } from "../../services/service";
import Storage from "../../utils/Storage";
import { setDialog } from "../slices/dialogSlice";
import {
  setCreateFailed,
  setCreateSnackbar,
  setCreateSuccess,
} from "../slices/formSlice";
import {
  createNewGroup,
  createNewOrg,
  createProjectStatuCheckListOrg,
  createProjectStatusOrg,
  createProjectStatusStageOrg,
  designESRUpdate,
  editGroup,
  editOrg,
  getGroupList,
  getGroupUserList,
  getLeadListOrg,
  getMainProjectStatusListOrg,
  getModeratorsListOrg,
  getOrgList,
  getOrgUserList,
  getOrganisationDetails,
  getPendingUserListOrg,
  getPrjectstatusCheckListOrg,
  getProjectListOrg,
  getProjectStatusListOrg,
  getProjectStatusStageListOrg,
  getUserListOrg,
  inviteNewUser,
  setDesignESRSuccess,
  setGroup,
  setGroupUserList,
  setLeadListOrg,
  setMainProjectStatusListOrg,
  setModeratorsListOrg,
  setOrgCreateDefaultSOSuccess,
  setOrgCreateDefaultSuccess,
  setOrgCreateSuccess,
  setOrgDefault,
  setOrgDefaultSO,
  setOrgList,
  setOrgUpdateSuccess,
  setOrgUserList,
  setOrganisationDetails,
  setPendingUserListOrg,
  setProjectListOrg,
  setProjectStatusCheckListOrg,
  setProjectStatusCreateSuccess,
  setProjectStatusListOrg,
  setProjectStatusStageCreateSuccess,
  setProjectStatusStageListOrg,
  setProjectStatusUpadteLoading,
  setUserlistOrg,
  setgroupLoadingFalse,
  setorgLoadingFalse,
  setorgUserLoadingFalse,
  updateProjectStatusOrder,
  updateProjectStatusStageOrderOrg,
} from "../slices/orgSlice";
import { updateProjectStatus } from "../slices/projectSlice";

function* onCreateOrgAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(setOrgCreateSuccess({ ...response.data }));
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setorgLoadingFalse());
  }
}

function* onCreateOrg() {
  yield takeLatest(createNewOrg.type, onCreateOrgAsync);
}

function* onDesignESRUpdateAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(setDesignESRSuccess({ ...response.data }));
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setorgLoadingFalse());
  }
}

function* onDesignESRUpdate() {
  yield takeLatest(designESRUpdate.type, onDesignESRUpdateAsync);
}

function* onCreateOrgDefaultAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(setOrgCreateDefaultSuccess({ ...response.data?.message }));
  } catch (error) {
    console.log("SagaError=------->", error);
  }
}

function* onCreateOrgDefault() {
  yield takeLatest(setOrgDefault.type, onCreateOrgDefaultAsync);
}

function* onCreateOrgDefaultSOAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(
      setOrgCreateDefaultSOSuccess({ ...response.data[0].orgdetails?.message })
    );
  } catch (error) {
    console.log("SagaError=------->", error);
  }
}

function* onCreateOrgDefaultSO() {
  yield takeLatest(setOrgDefaultSO.type, onCreateOrgDefaultSOAsync);
}

function* onEditOrgAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(setOrgUpdateSuccess({ ...response.data }));

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setorgLoadingFalse());
  }
}

function* onEditOrg() {
  yield takeLatest(editOrg.type, onEditOrgAsync);
}

function* onEditGroupAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setorgLoadingFalse());
  }
}

function* onEditGroup() {
  yield takeLatest(editGroup.type, onEditGroupAsync);
}

function* onLoadOrgAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    if (response) {
      // sessionStorage.setItem("org", JSON.stringify(response.data[0]));
      const userInfo = JSON.parse(sessionStorage.getItem("user"));

      const org = Storage.read(`org${userInfo?.id}`);
      if (org?.id) {
        const orgData = response?.data.filter((item) => item.id === org?.id);

        Storage.write(`org${userInfo?.id}`, orgData[0]);
      } else {
        Storage.write(`org${userInfo?.id}`, response.data[0]);
      }

      yield put(setOrgList(response.data));
    }

    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setorgLoadingFalse());
  }
}

function* onLoadOrg() {
  yield takeLatest(getOrgList.type, onLoadOrgAsync);
}

function* onLoadOrganisationDetailsAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    if (response.status === 200) {
      yield put(setOrganisationDetails({ ...response.data[0] }));
    }

    // }
  } catch (error) {
    console.log("error", error);
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
    }
  }
}

function* onLoadOrganisationDetails() {
  yield takeLatest(getOrganisationDetails.type, onLoadOrganisationDetailsAsync);
}

function* onLoadGroupAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setGroup([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setgroupLoadingFalse());
  }
}

function* onLoadGroup() {
  yield takeLatest(getGroupList.type, onLoadGroupAsync);
}

function* onCreateGroupAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    // if (response.status === 200) {
    // yield put(setOrgCreateSuccess({ ...response.message }));
    yield put(
      setCreateSuccess({
        message: "Group created successfully",
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
    yield put(setgroupLoadingFalse());

    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setorgLoadingFalse());
  }
}

function* onCreateGroup() {
  yield takeLatest(createNewGroup.type, onCreateGroupAsync);
}

function* onInviteUserAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    // if (response.status === 200) {
    // yield put(setOrgList({ ...response.data }));
    // }
    // yield put(setorgLoadingFalse());
    yield put(
      setCreateSuccess({
        message: "Invited User",
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    yield put(setorgLoadingFalse());

    yield put(
      setCreateSuccess({
        message: error?.message,
        createSuccess: false,
        statusCode: 600,
      })
    );
  }
}

function* onInviteUser() {
  yield takeLatest(inviteNewUser.type, onInviteUserAsync);
}

function* onLoadGroupUserAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url });
    yield put(setGroupUserList([...response.data]));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setgroupLoadingFalse());
  }
}

function* onLoadGroupUser() {
  yield takeLatest(getGroupUserList.type, onLoadGroupUserAsync);
}

function* onLoadOrgUserAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url });
    yield put(setOrgUserList([...response.data]));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setorgUserLoadingFalse());
  }
}

function* onLoadOrgUser() {
  yield takeLatest(getOrgUserList.type, onLoadOrgUserAsync);
}

function* onLoadProjectListInOrganisationAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    if (response.data.prjdata)
      yield put(setProjectListOrg([...response.data.prjdata]));
  } catch (error) {
    console.log("error");
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* onLoadProjectList() {
  yield takeLatest(
    getProjectListOrg.type,
    onLoadProjectListInOrganisationAsync
  );
}

function* onLoadMainProjectListInOrganisationAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    if (response.data?.length > 0)
      yield put(setMainProjectStatusListOrg([...response.data]));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* onLoadMainProjectList() {
  yield takeLatest(
    getMainProjectStatusListOrg.type,
    onLoadMainProjectListInOrganisationAsync
  );
}

function* onLoadLeadListInOrganisationAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    if (response.data.leadata)
      yield put(setLeadListOrg([...response.data.leadata]));
  } catch (error) {
    console.log("error");
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* onLoadCheckListInStatus({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.data.leadata)
    yield put(setProjectStatusCheckListOrg([...response.data]));
  } catch (error) {
    console.log("error");
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* onLoadLeadList() {
  yield takeLatest(getLeadListOrg.type, onLoadLeadListInOrganisationAsync);
}
function* onLoadCheckList() {
  yield takeLatest(getPrjectstatusCheckListOrg.type, onLoadCheckListInStatus);
}

function* onLoadUserListInOrganisationAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url });
    if (response.status === 200) {
      yield put(setUserlistOrg([...response.data]));
      let moderators = response.data?.filter(
        (item) => item.isModerator == "true"
      );
      yield put(
        setModeratorsListOrg([
          ...moderators?.map((item) => {
            return {
              ...item,
              id: item.userId,
            };
          }),
        ])
      );
    }
  } catch (error) {
    console.log("error");
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* onLoadUserList() {
  yield takeLatest(getUserListOrg.type, onLoadUserListInOrganisationAsync);
}

function* onLoadPendingUserListInOrganisationAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    if (response.status === 200)
      yield put(setPendingUserListOrg([...response.data]));
  } catch (error) {
    console.log("error");
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* OnLoadPendingUsersList() {
  yield takeLatest(
    getPendingUserListOrg.type,
    onLoadPendingUserListInOrganisationAsync
  );
}

function* onLoadModeratorListInOrganisationAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url });
    if (response.status === 200) {
      if (response.data && Array.isArray(response.data)) {
        yield put(setModeratorsListOrg([...response.data]));
      } else if (response.data) {
        yield put(setModeratorsListOrg([response.data]));
      }
    }
  } catch (error) {
    console.log("error");
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* OnLoadModeratorList() {
  yield takeLatest(
    getModeratorsListOrg.type,
    onLoadModeratorListInOrganisationAsync
  );
}

function* onLoadProjectStatusListInOrganisationAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    if (response.status === 200)
      yield put(setProjectStatusListOrg([...response.data]));
  } catch (error) {
    console.log("error", error);
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* OnLoadProjectStatusList() {
  yield takeLatest(
    getProjectStatusListOrg.type,
    onLoadProjectStatusListInOrganisationAsync
  );
}

function* onLoadProjectStatusStageListInOrganisationAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    if (response.status === 200)
      yield put(setProjectStatusStageListOrg([...response.data]));
  } catch (error) {
    console.log("error", error);
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
    //yield put(setorgUserLoadingFalse());
  }
}

function* OnLoadProjectStatusStageList() {
  yield takeLatest(
    getProjectStatusStageListOrg.type,
    onLoadProjectStatusStageListInOrganisationAsync
  );
}

function* onCreateProjectStatusStageInOrgAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    // yield put(setOrgCreateSuccess({ ...response.data }));
    if (response?.data.message.includes("Already exists")) {
      yield put(
        setCreateFailed({
          ...response?.data,
          createSuccess: false,
          statusCode: 404,
        })
      );
    } else {
      yield put(
        setCreateSnackbar({
          ...response?.data,
          createSuccess: response?.status === 201 ? true : false,
          statusCode: response?.status,
        })
      );
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    // yield put(setorgLoadingFalse());
  }
}

function* onUpdateProjectStatusStageOrderInOrgAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    // yield put(setOrgCreateSuccess({ ...response.data }));
    // yield put(
    //   setCreateSuccess({
    //     ...response?.data,
    //     createSuccess: response?.status === 201 ? true : false,
    //     statusCode: response?.status,
    //   })
    // );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    // yield put(setorgLoadingFalse());
  }
}

function* OnCreateProjectStatusStage() {
  yield takeLatest(
    createProjectStatusStageOrg.type,
    onCreateProjectStatusStageInOrgAsync
  );
}

function* OnUpdateProjectStatusStageOrder() {
  yield takeLatest(
    updateProjectStatusStageOrderOrg.type,
    onUpdateProjectStatusOrderInOrgAsync
  );
}

function* onCreateProjectStatusInOrgAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    // yield put(setOrgCreateSuccess({ ...response.data }));
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    // yield put(setorgLoadingFalse());
  }
}

function* onCreateProjectStatusCheckListInOrgAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    // yield put(setOrgCreateSuccess({ ...response.data }));
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    // yield put(setorgLoadingFalse());
  }
}

function* onUpdateProjectStatusOrderInOrgAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        message: "Order updated ",
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
    yield put(setProjectStatusUpadteLoading(false));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    // yield put(setorgLoadingFalse());
  }
}

function* OnCreateProjectStatus() {
  yield takeLatest(
    createProjectStatusOrg.type,
    onCreateProjectStatusInOrgAsync
  );
}
function* OnUpdateProjectStatusOrder() {
  yield takeLatest(
    updateProjectStatusOrder.type,
    onUpdateProjectStatusOrderInOrgAsync
  );
}

function* OnCreateProjectStatusCheckList() {
  yield takeLatest(
    createProjectStatuCheckListOrg.type,
    onCreateProjectStatusCheckListInOrgAsync
  );
}

export const orgSagas = [
  fork(onCreateOrg),
  fork(onDesignESRUpdate),
  fork(onEditOrg),
  fork(onEditGroup),
  fork(onLoadOrg),
  fork(onLoadGroup),
  fork(onCreateGroup),
  fork(onInviteUser),
  fork(onLoadGroupUser),
  fork(onLoadOrgUser),
  fork(onCreateOrgDefault),
  fork(onCreateOrgDefaultSO),
  fork(onLoadProjectList),
  fork(onLoadLeadList),
  fork(onLoadUserList),
  fork(OnLoadModeratorList),
  fork(OnLoadProjectStatusList),
  fork(OnLoadProjectStatusStageList),
  fork(OnCreateProjectStatusStage),
  fork(OnCreateProjectStatus),
  fork(onLoadOrganisationDetails),
  fork(OnLoadPendingUsersList),
  fork(onLoadMainProjectList),
  fork(OnUpdateProjectStatusOrder),
  fork(OnUpdateProjectStatusStageOrder),
  fork(OnCreateProjectStatusCheckList),
  fork(onLoadCheckList),
];
