import FolderAddIcon from "../../../../assets/images/addfolder";
import CloseIcon from "../../../../assets/images/close";
import FolderIcon from "../../../../assets/images/folder";
import Button from "../../../buttons/Button";
import CardTitle from "../../../dashboard/CardTitle";

import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNewFolder,
  folderBulkDownload,
  getFileList,
} from "../../../../redux/slices/fileUploadSlice";
import { setCreateFailed } from "../../../../redux/slices/formSlice";
import Storage from "../../../../utils/Storage";
import { modalStyle } from "../../../constants/ConstData";
import ThreeDotDropdown from "../../../dropdown/ThreeDotDropdown";
import EmptyList from "../../../emptylist/EmptyList";
import BasicModal from "../../../modal/Modal";
import ModalBody from "../../../modal/ModalBody";
import ModalHead from "../../../modal/ModalHead";
import FilesCard from "../fileupload/FilesCard";
import {
  GetObjectCommand,
  ListObjectsV2Command,
  S3Client,
} from "@aws-sdk/client-s3";
import JSZip from "jszip";
import { useSelector } from "react-redux";
import { AxiosInstance } from "../../../../api";
import {
  setModal,
  setOpenDownloadModal,
} from "../../../../redux/slices/modalSlice";

const options = [{ name: "Download", value: "download" }];

const FileFolders = ({ type, folderList }) => {
  const [showCreate, setShowCreate] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const { id } = useParams();
  const navigate = useNavigate();

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const bulkDownload = (folder) => {
    const sanitizedFileName = folder.name.replace(/[^\w.-]/g, "_");
    const newData = {
      folder: `${sanitizedFileName}-${folder.id}`,
      projectId: Number(id),
      orgId: orgId,
    };

    dispatch(
      folderBulkDownload({
        url: `/crm/org/${orgId}/${type}/${id}/file/download`,
        data: newData,
        params: {},
      })
    );
    // downloadFolderFromS3(orgId,Number(id),`${sanitizedFileName}-${folder.id}`)
  };

  const downloadFolderFromS3 = async (orgId, projectId, folder) => {
    // zipFile();
    let projectLocation = "uat/uploads/Projects";
    const folderName = folder
      ? `${projectLocation}/${orgId}/${projectId}/${folder}`
      : `${projectLocation}/${orgId}/${projectId}`;
    const listParams = {
      Bucket: process.env.REACT_APP_BUCKET,
      Prefix: folderName,
    };
    const s3 = new S3Client({
      apiVersion: "latest",
      region: process.env.REACT_APP_REGION,
      credentials: {
        secretAccessKey: process.env.REACT_APP_SECRETE_KEY,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      },
    });
    const listCommand = new ListObjectsV2Command(listParams);
    const listResponse = await s3.send(listCommand);
    if (!listResponse.Contents || listResponse.Contents.length === 0) {
    }
    const totalFiles = listResponse.Contents.length;
    // const totalFiles=validObjectKeys.length;
    let filesProcessed = 0;
    for (const item of listResponse.Contents) {
      const objectKey = item.Key;
      // if(!validObjectKeys.includes(objectKey)){//file deleted no need to fetch file from s3
      //   continue;
      // }
      const getObjectParams = {
        Bucket: process.env.REACT_APP_BUCKET,
        Key: objectKey,
      };
      const getObjectCommand = new GetObjectCommand(getObjectParams);
      const objectData = await s3.send(getObjectCommand);
      const readableStream = objectData.Body.transformToWebStream();
      const fileBuffer = [];
      let isEnded = false;
      let i = 1;
      //  const nodeReadableStream = Readable.fromWeb(readableStream);
      readableStream.on("data", (chunk) => {
        fileBuffer.push(chunk);
      });

      readableStream.on("error", (err) => {
        isEnded = true; // Mark as ended to prevent further processing
      });

      readableStream.on("end", () => {
        if (!isEnded) {
          const buffer = Buffer.concat(fileBuffer);
          // archive.append(buffer, { name: objectKey });
        }
        filesProcessed++;
        if (filesProcessed === totalFiles) {
          // archive.finalize();
        }
      });
    }
  };

  const zipFile = async () => {
    const zip = new JSZip();
    zip.file("hello.txt", "hello world");
    zip.file("file2.txt", "this is file 2");
    const content = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = "download.zip";
    link.click();
  };

  const handleSelect = (value, folder) => {
    switch (value) {
      case "download":
        // bulkDownload(folder);
        loadFileList(folder);
        break;

      default:
        navigate("/dashboard");
    }
  };

  const addNewFolder = () => {
    setShowCreate(true);
  };

  const folderSelect = (item) => {
    setSelectedFolder(item);
    setIsOpen(true);
  };

  const handleClose = () => {
    setSelectedFolder(null);
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const containsName = folderList.some(
        (user) => user.name === e.target.value
      );
      if (containsName) {
        dispatch(
          setCreateFailed({
            message: "Name already exists",
            createSuccess: true,
            statusCode: 600,
          })
        );
      } else {
        const newData = { folders: [{ name: e.target.value, isactive: 1 }] };
        dispatch(
          createNewFolder({
            url: `/crm/org/${orgId}/${type}/${id}/file/folder`,
            data: newData,
            params: {},
          })
        );
      }

      setShowCreate(false);
    }
  };
  const fileList = useSelector((state) => state.upload.fileList);
  const loading = useSelector((state) => state.upload.fileLoading);
  const loadFileList = async (folderObj) => {
    const response = await AxiosInstance.get(
      `/crm/org/${orgId}/${type}/${id}/file/folder/${folderObj.id}`
    );
    if (response.data.status == 201) {
      try {
        if (response.data.data.length > 0) {
          let urls = response.data.data.map((item) => item.url);

          dispatch(setOpenDownloadModal({ openDownloadModal: true }));
          const urlResponse = await AxiosInstance.post(
            `/crm/org/${orgId}/proj/${id}/file/url`,
            urls
          );
          if (urlResponse.data.status === 200) {
            dispatch(setOpenDownloadModal({ openDownloadModal: false }));
            const link = document.createElement("a");
            link.href = urlResponse.data.data.signedUrl;
            link.download = "download";
            link.click();
          }
        }
      } catch (error) {
        //  alert('error during download')
      } finally {
        dispatch(setOpenDownloadModal({ openDownloadModal: false }));
      }
    }
    // dispatch(
    //   getFileList({
    //     url: `/crm/org/${orgId}/${type}/${id}/file/folder/${folderObj.id}`,
    //     params: {},
    //   })
    // );

    // if(fileList.length>0){
    //   let urls=fileList.map((item)=>item.url)
    //   dispatch(
    //     folderBulkDownload({
    //       url: `/crm/org/${orgId}/proj/${id}/file/url`,
    //       data: urls,
    //       params: {
    //         orgId,
    //         projectId:id
    //       },
    //     })
    //   );
    // }
  };

  return (
    <>
      <div className="card_container">
        <CardTitle title="Files & Folders" type="project_view">
          <Button
            onClick={addNewFolder}
            className="outline_button icon_button d_flex align_items_center "
          >
            New Folder
            <FolderAddIcon
              style={{ marginLeft: "10px", padding: "2px" }}
              color="#189d3d"
            />
          </Button>
        </CardTitle>
        <Box className="card_content">
          {folderList?.length > 0
            ? folderList.map((item) => (
                <div
                  key={item?.id}
                  className="d_flex align_items_center justify_content_between w_100"
                >
                  <div
                    onClick={() => folderSelect(item)}
                    className="d_flex align_items_center pointer"
                  >
                    <FolderIcon
                      style={{ marginRight: "10px", padding: "2px" }}
                    />
                    {item.name}
                  </div>
                  <ThreeDotDropdown
                    options={options}
                    onSelect={(value) =>
                      handleSelect(value, { id: item.id, name: item.name })
                    }
                  />
                </div>
              ))
            : !showCreate && <EmptyList message="No folders found" />}
          {showCreate && (
            <div className="d_flex align_items_center">
              <FolderIcon style={{ marginRight: "10px", padding: "2px" }} />
              <input
                type="text"
                name="name"
                autoFocus={true}
                onBlur={() => setShowCreate(false)}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}
        </Box>
      </div>
      <BasicModal
        isOpen={isOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <ModalHead title={selectedFolder?.name}>
          <div className="modal_header_button_wrapper">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </ModalHead>
        <ModalBody>
          <div style={{ position: "relative", height: "100%" }}>
            <FilesCard
              title={selectedFolder?.name}
              // list={projectData?.files}
              folderId={selectedFolder?.id}
              wrapperClass="upload_wrapper"
              // type="file_upload"
              type={type}
              onMinimize={handleClose}
            />
          </div>
        </ModalBody>
      </BasicModal>
    </>
  );
};

export default FileFolders;
