import {
  editProject,
  getProperty,
  setProjectProperty,
  updatePropertyDetails,
} from "../../../../redux/slices/projectSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateView from "../../../date/DateView";
import { useLoadScript } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import ThreeDotDropdown from "../../../dropdown/ThreeDotDropdown";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { stringAvatar } from "../../../../utils/util";
import { getOrgUserList } from "../../../../redux/slices/orgSlice";
import ProjectAssignDetails from "../../create/ProjectAssignDetails";
import Storage from "../../../../utils/Storage";
import { Link } from "react-router-dom";
import Button from "../../../buttons/Button";
import { Edit } from "@mui/icons-material";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";
import PropertyDetailsForm from "./PropertyDetailsForm";
import CardTitle from "../../../dashboard/CardTitle";
import LabelValue from "../LabelValue";

const libraries = ["places"];

const PropertyDetailsCard = ({ type, cardData }) => {
  const dispatch = useDispatch();

  const [Coordinates, setCoordinates] = useState({});
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const [isOpen, setIsOpen] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const propertyItems = useSelector((state) => state.project.propertyItems);
  const propertyLoading = useSelector((state) => state.project.propertyLoading);

  const google = window.google;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  useEffect(() => {
    if (
      cardData &&
      cardData?.address &&
      cardData?.propertyDetails &&
      Array.isArray(cardData?.propertyDetails) &&
      cardData?.propertyDetails?.length === 0
    )
      dispatch(
        getProperty({
          url: `https://zillow-com1.p.rapidapi.com/property`,
          params: { address: cardData?.address },
          // pageType: type,
        })
      );
  }, [cardData]);

  const handleSelect = (address) => {
    if (isLoaded) {
      var geocoder = new google.maps.Geocoder();
      // var address = document.getElementById("address").value;
      geocoder.geocode({ address: address }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          // do something with the geocoded result
          const { lat, lng } = getLatLng(results[0]);
          setCoordinates({ lat: lat, lng: lng });
        }
      });
    }
  };

  const editPropertyDetails = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (cardData?.address && isLoaded) {
      handleSelect(cardData?.address);

      if (
        Array.isArray(cardData?.addressDetails) &&
        cardData?.addressDetails.length === 0
      ) {
        LoadAddress(cardData?.address);
      }
    }
  }, [cardData, isLoaded]);

  const LoadAddress = (description) => {
    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const addressComponents = results[0].address_components;
      let streetNumber = "";
      let area = "";
      let city = "";
      let county = "";
      let state = "";
      let country = "";
      let postalCode = "";

      addressComponents.forEach((component) => {
        if (component.types.includes("street_number")) {
          streetNumber = component.long_name;
        }
        if (component.types.includes("route")) {
          area = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_2")) {
          county = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        }
      });

      const addressValue = {
        description: description,
        streetNumber: streetNumber,
        area: area,
        city: city,
        county: county,
        state: state,
        country: country,
        postalCode: postalCode,
      };
      setAddressDetails(addressValue);
      // return addressValue;
    });
    // .catch((error) => {});
  };

  useEffect(() => {
    if (
      cardData?.propertyDetails &&
      !Array.isArray(cardData?.propertyDetails)
    ) {
      const details = {
        propertyType: cardData?.propertyDetails?.propertyType,
        yearofBuilt: cardData?.propertyDetails?.yearofBuilt,
        propertyArea: cardData?.propertyDetails?.propertyArea,
        homeType: cardData?.propertyDetails?.homeType,
        architecturalStyle: cardData?.propertyDetails?.architecturalStyle,
        foundation: cardData?.propertyDetails?.foundation,
        roof: cardData?.propertyDetails?.roof,
        imageUrl: cardData?.propertyDetails?.imageUrl,
        wall: cardData?.propertyDetails?.wall,
      };
      setPropertyDetails(details);
    } else if (propertyItems && Object.keys(propertyItems).length > 0) {
      const details = {
        propertyType: propertyItems?.propertyTypeDimension,
        yearofBuilt: propertyItems?.yearBuilt,
        propertyArea: propertyItems?.resoFacts?.buildingArea
          ? propertyItems?.resoFacts?.buildingArea +
            " " +
            propertyItems?.livingAreaUnits
          : "",
        homeType: propertyItems?.homeType?.replace(/_/g, " "),
        architecturalStyle: propertyItems?.resoFacts?.architecturalStyle,
        foundation: propertyItems?.resoFacts?.foundationDetails[0],
        roof: propertyItems?.resoFacts?.roofType,
        wall: propertyItems?.resoFacts?.wallType,
        imageUrl: propertyItems?.imgSrc,
      };
      const editedData = {
        propertyDetails: [details],
      };

      dispatch(
        updatePropertyDetails({
          url: `crm/org/${orgId}/proj/${cardData?.id}`,
          data: editedData,
          params: {},
        })
      );
      setPropertyDetails(details);
    }
  }, [propertyItems, cardData?.propertyDetails]);

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    if (addressDetails) {
      // const newData = {
      //   ...propertyDetails,
      //   countryName: addressDetails?.country,
      //   stateName: addressDetails?.state,
      //   cityName: addressDetails?.city,
      //   postalCode: addressDetails?.postalCcode,
      //   streetNo: addressDetails?.streetNumber,
      //   county: addressDetails?.county,
      // };
      const editedData = {
        addressDetails: addressDetails,
      };

      dispatch(
        updatePropertyDetails({
          url: `crm/org/${orgId}/proj/${cardData?.id}`,
          data: editedData,
          params: {},
        })
      );
    }
  }, [addressDetails]);

  if (propertyLoading) {
    return <></>;
  }

  return (
    <>
      <CardTitle
        style={{ paddingLeft: 0, paddingRight: 0 }}
        title="Property Details"
        type="project_view"
      >
        <IconButton
          className="marginL10"
          size="small"
          onClick={editPropertyDetails}
        >
          <Edit />
        </IconButton>
      </CardTitle>
      <>
        {!propertyLoading && isLoaded && Coordinates && (
          <img
            style={{
              width: "225px",
              height: "110px",
              borderRadius: "4px",
            }}
            src={
              propertyDetails?.imageUrl
                ? propertyDetails?.imageUrl
                : `https://maps.googleapis.com/maps/api/streetview?channel=rdc-streetview&client=gme-movesalesinc&size=640x480&location=${Coordinates?.lat},${Coordinates?.lng}
               &key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            }
            alt="residency"
            loading="lazy"
          />
        )}

        <div>
          <LabelValue
            className="m_0"
            label="Property Type"
            value={propertyDetails?.propertyType}
            buttonLabel="Add Property Type"
            onClick={editPropertyDetails}
          />

          <LabelValue
            className=""
            label="Year Built"
            value={propertyDetails?.yearofBuilt}
            buttonLabel="Add Year Built"
            onClick={editPropertyDetails}
          />

          <LabelValue
            className=""
            label="Area"
            value={propertyDetails?.propertyArea}
            buttonLabel="Add Property Area"
            onClick={editPropertyDetails}
          />
          <LabelValue
            className=""
            label="Home Type"
            value={propertyDetails?.homeType}
            buttonLabel="Add Home Type"
            onClick={editPropertyDetails}
          />
          <LabelValue
            className=""
            label="Architectural Style"
            value={propertyDetails?.architecturalStyle}
            buttonLabel="Add Architectural Style"
            onClick={editPropertyDetails}
          />

          <p>
            <b>Foundation: </b>
            <span>
              {propertyDetails?.foundation ?? (
                <Button
                  onClick={editPropertyDetails}
                  className="default_button link_blue border_none p_0"
                >
                  Add Foundation
                </Button>
              )}
            </span>
          </p>

          <p>
            <b>Roof: </b>
            <span>
              {propertyDetails?.roof ?? (
                <Button
                  onClick={editPropertyDetails}
                  className="default_button link_blue border_none p_0"
                >
                  Add Roof
                </Button>
              )}
            </span>
          </p>

          <LabelValue
            className=""
            label="Wall"
            value={propertyDetails?.wall}
            buttonLabel="Add Wall"
            onClick={editPropertyDetails}
          />
        </div>
        <BasicModal
          isOpen={isOpen}
          onClose={handleClose}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "500px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <PropertyDetailsForm
            // id={id}
            // projectData={projectData}
            propertyDetails={propertyDetails}
            onClose={handleClose}
          />
        </BasicModal>
      </>
    </>
  );
};

export default PropertyDetailsCard;
